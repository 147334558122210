import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Link } from "../components/StyledLink";
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const BackgroundSection = ({ className, children }) => (
    <StaticQuery query={graphql`
      query {
        desktop: file(relativePath: { eq: "mtn.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
     render={data => {
       // Set ImageData.
       const imageStack = [
         `linear-gradient(rgba(0, 0, 0, 0.4), rgba(255, 255, 88, 0.6))`,
         data.desktop.childImageSharp.fluid]
       return (
          <BackgroundImage Tag="section"
                           className={className}
                           fluid={imageStack}
                           backgroundColor={`#040e18`}
          >
          {children}
          </BackgroundImage>
       )
     }
     }
    />
)


const HomepageHero = styled(BackgroundSection)`
  background: no-repeat center center fixed;
  background-size: cover;
  // background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(255, 255, 88, 0.6));
  padding-top: 20%;
  text-align: center;
  height: 100vh;
  width: 100vw;
`

const HomepageSection = styled.section`
  border-top: 3px solid rgba(155,67,99,0.9);
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`

const HeroTitle = styled.h1`
  font-size: 6em;
  font-weight: 200 !important;
  letter-spacing: 14px;
  color: rgba(155,255,255,0.9);
`

const NoWrapText = styled.span`
  display:inline-block;
`

const WhiteText = styled.p`
  font-weight: bold;
  color: rgba(255,255,255,0.95);
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`phrase`, `litmag`, `magazine`, `music`]} />
    <HomepageHero id='home'>
      <HeroTitle>
        <NoWrapText>PHR</NoWrapText>
        <NoWrapText>ASE</NoWrapText>
      </HeroTitle>
      <WhiteText>A MAGAZINE</WhiteText>
      <WhiteText>A rose is a rose is a lifeboat.</WhiteText>
    </HomepageHero>
    <HomepageSection id='about'>
      <p>PHRASE is a magazine devoted to literature, music, and the arts.</p>
      <p>We publish deep, probing work that flattens our hearts between its palms.</p>
    </HomepageSection>
    <HomepageSection id='issue'>
      <p>Issue 1 Coming soon</p>
      <Link to='/submit'>submit to PHRASE</Link>
    </HomepageSection>
  </Layout>
)

export default IndexPage
